import React, { lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const Geotrust = lazy(() => import('sections/hero/Geotrust.js'));
const GeotrustPrecios = lazy(() => import('sections/services/GeotrustPrecios.js'));
const BotonSanGeo = lazy(() => import('sections/services/BotonSanGeo.js'));
const BoxCol2GeoTrust = lazy(() => import('sections/hero/BoxCol2GeoTrust.js'));
const SelloSitelock = lazy(() => import('sections/about/SelloSitelock.js'));
const SeguridadInformatica = lazy(() => import('sections/about/SeguridadInformatica.js'));
const EspecSSLGeo = lazy(() => import('sections/services/EspecSSLGeo.js'));
const FaqGeoTrust = lazy(() => import('../../sections/services/FaqGeotrust')); // Asegúrate de que la ruta sea correcta
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));

class Index extends React.Component {

  render() {
    
    
    return (
      <div>
        <Helmet>
          <title>GeoTrust: Certificados SSL Digitales en Bogotá, Colombia</title>
          <meta name="description" content="Protege tu sitio web con Certificados SSL Digitales de GeoTrust. Garantiza seguridad y confianza en línea. Descubre nuestras soluciones en Bogotá, Colombia." />
        </Helmet>
        <Layout>
          <Geotrust />           
          <GeotrustPrecios />          
          <EspecSSLGeo />          
          <BoxCol2GeoTrust />          
          <SelloSitelock />
          <SeguridadInformatica />          
          <FaqGeoTrust />
          <BotonSanGeo />          
          <Llamenos />
          <ContactCreative />
          <Footer />
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeVideoTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`